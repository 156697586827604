import Appsignal from "@appsignal/javascript";
import { plugin } from "@appsignal/plugin-window-events";

const appsignal = new Appsignal({
  key: process.env.APPSIGNAL_PUSH_API_KEY_FRONTEND || "",
  revision: process.env.HEROKU_SLUG_COMMIT || "",
});

appsignal.use(plugin());

export default appsignal;
